import styled from "styled-components"
import v from "../../_globalSettings/var"

const StyledContent = styled.div`
	padding-top: ${v.headerHeight};
	display: flex;
	flex-direction: column;
`

export default StyledContent
