import styled from "styled-components"
import { color } from "../../_globalSettings/skin"
import { breakpoint } from "../../_globalSettings/var"

const StyledFooter = styled.footer`
	background-color: ${color.night.default};
	color: white;
	display: flex;
	flex-wrap: wrap;
	font-size: 14px;
	line-height: 21px;
	flex-direction: column;
	a {
		color: white;
		text-decoration: none;
	}
	h3 {
		color: white;
		text-transform: uppercase;
		padding-bottom: 24px;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.05em;
	}
	.footer__bottom-links {
		width: 100%;
		display: flex;
		margin-top: 64px;
		border-top: 1px solid #474d6c;
		height: 69px;
		justify-content: center;
		align-items: center;
		color: rgba(255, 255, 255, 0.7);
		a {
			color: rgba(255, 255, 255, 0.7);
		}
	}
	.footer__wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: 8.33%;
	}
	.footer__down {
		padding: 0 16px;
	}
	.footer__content {
		padding-top: 65px;
		ul {
			margin: 0;
			list-style-type: none;
			li {
				line-height: 160%;
			}
		}
	}
	.footer__links a {
		display: inline-block;
		padding-bottom: 14px;
		color: rgba(255, 255, 255, 0.7);
		&:hover {
			color: white;
		}
	}
	.footer__address {
		margin-top: 20px;
		p {
			color: rgba(255, 255, 255, 0.7);
			line-height: 160%;
			margin: 0;
		}
		strong {
			color: white;
			font-weight: normal;
		}
	}
	.footer__icon {
		transform-origin: center center;
		transition: transform 0.2s;
		display: inline-block;
		line-height: 0;
		margin-right: 17px;
		vertical-align: middle;
	}
	.footer__contact {
		margin-bottom: 6px;
		a {
			vertical-align: middle;
			&:hover {
				.footer__icon {
					transform: scale(1.3);
				}
			}
		}
	}
	.footer__social {
		&:hover {
			transform: scale(1.3);
		}
	}

	@media (max-width: ${breakpoint.default.to}px) {
		padding-top: 70px;
		.footer__wrap {
			padding-right: 0;
			flex-wrap: wrap;
			.footer__content {
				padding-top: 50px;
			}
		}
		.footer__logo {
			display: none;
		}
	}

	@media (max-width: ${breakpoint.min.to}px) {
		.footer__bottom-links {
			flex-direction: column;
			align-items: flex-start;
			padding: 10px 0;
			height: auto;
			.footer__down {
				padding: 10px 0;
			}
			.footer__links-divider {
				display: none;
			}
		}
		.footer__content {
			width: 100%;
		}
		.footer__row {
			flex-direction: column;
		}
		.footer__wrap {
			flex-direction: column;
		}
	}
`

export default StyledFooter
