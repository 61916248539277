import styled from "styled-components"
import { breakpoint } from "../../_globalSettings/var"
import { color } from "../../_globalSettings/skin"

const StyledMenu = styled.ul`
	display: flex;
	position: relative;
	list-style-type: none;
	> li {
		display: flex;
		align-items: center;
		min-height: 80px;
		color: ${color.main.text3};
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		padding: 0 28px;
		cursor: pointer;
		> a {
			color: ${color.main.text3};
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 600;
			text-decoration: none;
			padding: 32px 0;
		}
		&.menu__item--has-dropdown {
			&::after {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				margin-left: 8px;
				border-width: 4px 4px 0 4px;
				border-color: ${color.main.text3} transparent transparent transparent;
			}
		}
		.menu__dropdown {
			max-height: 0;
			transition: max-height 0.3s ease-out;
			overflow: hidden;
			position: absolute;
			top: 80px;
			background: #fff;
			list-style-type: none;
			box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1),
				0px 20px 32px rgba(9, 20, 79, 0.05);
			border-radius: 2px;
			li {
				height: 54px;
				display: flex;
				align-items: center;
				a {
					color: ${color.main.text1};
					text-decoration: none;
					text-transform: none;
					font-size: 14px;
					padding: 18px 24px;
					width: 100%;
					font-weight: 500;
				}
				&:hover a {
					color: ${color.main.primary1};
				}
			}
		}
		&:hover {
			color: ${color.main.text1};
			> a {
				color: ${color.main.text1};
			}
			.menu__dropdown {
				max-height: 500px;
				transition: max-height 0.3s ease-in;
			}
			&.menu__item--has-dropdown::after {
				border-color: ${color.main.text1} transparent transparent transparent;
			}
		}
	}
	> li:last-of-type {
		padding-right: 0;
		padding-left: 0;
		a {
			padding-left: 28px;
		}
	}

	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		padding: 96px 24px 0;
		> li {
			padding-right: 0;
			flex-direction: column;
			align-items: flex-start;
			.menu__dropdown {
				display: block;
				position: static;
				max-height: 500px;
				box-shadow: none;
				margin-bottom: 50px;
				margin-top: 8px;
				li {
					padding: 0;
					height: 56px;
					a {
						font-size: 20px;
						padding-left: 0;
					}
				}
			}
			&:not(.menu__item--has-dropdown) {
				a {
					font-size: 20px;
					color: ${color.main.text1};
					text-transform: none;
				}
			}
			&.menu__item--has-dropdown {
				&::after {
					display: none;
				}
			}
		}
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		> li {
			padding: 0;
		}
		> li:last-of-type {
			a {
				padding-left: 0;
			}
		}
	}
`
export default StyledMenu
