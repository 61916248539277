import React from "react"
import { Link } from "gatsby"
import StyledMenu from "./style"

const Menu = () => (
	<StyledMenu>
		<li className="menu__item--has-dropdown">
			Aktivity
			<ul className="menu__dropdown">
				<li>
					<Link to="/tvorba-zadani">Pomoc s tvorbou zadání</Link>
				</li>
				<li>
					<Link to="/zamereno-na-vyvoj">Zaměřeno na vývoj</Link>
				</li>
			</ul>
		</li>
		<li className="menu__item--has-dropdown">
			ASWA
			<ul className="menu__dropdown">
				<li>
					<Link to="/clenove">Členové ASWA</Link>
				</li>
				<li>
					<Link to="/pro-media">Pro média</Link>
				</li>
				<li>
					<Link to="/kontakt">Kontakty</Link>
				</li>
			</ul>
		</li>
		<li>
			<Link to="/clenstvi">Členství</Link>
		</li>
	</StyledMenu>
)

export default Menu
