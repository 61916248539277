import React, { useEffect } from "react"
import { node, shape } from "prop-types"
import { Provider as AlertProvider } from "react-alert"
import Alert, { options } from "../Alert"
import Header, { headerProps } from "./Header"
import Footer, { footerProps } from "./Footer"
import Content from "./Content"
import SEO, { SEOProps } from "../Seo"
import GlobalStyle from "./style"
import useGlobal from "../../store"

const Layout = ({
	children,
	header,
	footer,
	footerLeft,
	footerMiddle,
	footerRight,
	seo,
	newsletter,
}) => {
	const [globalState, globalActions] = useGlobal()

	useEffect(() => {
		function handleResize() {
			globalActions.viewportDimensionAction()
		}
		handleResize()
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return (
		<AlertProvider template={Alert} {...options}>
			<div id="outer-container">
				<div id="inner-container">
					<GlobalStyle modalOpen={globalState.modalOpen} />
					<SEO {...seo} title={seo.SeoTitle} description={seo.SeoDescription} />
					<Header {...header} />
					<div id="page-wrap">
						<Content>{children}</Content>
						<Footer
							{...footer}
							newsletter={newsletter}
							footerLeft={footerLeft}
							footerMiddle={footerMiddle}
							footerRight={footerRight}
						/>
					</div>
				</div>
			</div>
		</AlertProvider>
	)
}
export default Layout

export const layoutProps = {
	header: shape(headerProps).isRequired,
	footer: shape(footerProps).isRequired,
	seo: shape(SEOProps).isRequired,
}

Layout.propTypes = {
	children: node.isRequired,
	...layoutProps,
}
