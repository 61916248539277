import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"

const StyledNewsletter = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 120px 0;
	width: 100%;
	color: ${color.dark.default};
	h2 {
		max-width: 284px;
		padding-bottom: 0;
	}
	form {
		width: 452px;
		max-width: 33%;
	}
	.nl__paragraph {
		font-size: 18px;
		line-height: 165%;
		max-width: 250px;
		padding: 0 15px;
		color: ${color.main.text2};
	}
	.form-input {
		margin-bottom: 0 !important;
		background: #fff !important;
		height: 80px;
		padding: 25px 85px 25px 25px !important;
		border: 2px solid ${color.light.n500} !important;
		box-shadow: 0px 20px 32px rgba(9, 20, 79, 0.05);
		border-radius: 4px;
		font-size: 18px;
		&::placeholder {
			color: ${color.main.text3};
			opacity: 1;
		}
	}
	.form__submit {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	@media (max-width: ${breakpoint.preMin.to}px) {
		flex-direction: column;
		padding: 100px 0;
		.form__fieldset {
			padding-bottom: 0;
		}
		.form-field-error {
			margin: 0;
		}
		.invalid-input {
			margin-bottom: 5px;
		}
		.nl__paragraph {
			max-width: 100%;
			padding: 40px 0;
		}
		.form-input {
			font-size: 16px;
			height: 64px;
			padding: 16px 85px 16px 16px !important;
		}
		form {
			max-width: 100%;
		}
		.form__submit button {
			height: 48px !important;
			width: 48px !important;
		}
	}
`
export default StyledNewsletter
