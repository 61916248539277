import React from "react"
import { node } from "prop-types"
import StyledContent from "./style"

const Content = ({ children }) => {
	return <StyledContent>{children}</StyledContent>
}

export const contentProps = {
	children: node.isRequired,
}

Content.propTypes = contentProps

export default Content
