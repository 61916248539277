import styled from "styled-components"
import v, { useZIndex } from "../../_globalSettings/var"

const StyledHeader = styled.header`
	height: ${v.headerHeight};
	width: 100%;
	display: flex;
	background-color: white;
	box-sizing: border-box;
	align-items: center;
	justify-content: space-between;
	.header__logo {
		padding-right: 20px;
		position: relative;
		z-index: ${useZIndex("header") + 1};
	}
	.header__mobile-logo {
		display: none;
		z-index: ${useZIndex("header") + 1};
	}
	@media (max-width: 1071px) {
		.header__logo {
			display: none;
		}
		.header__mobile-logo {
			display: block;
		}
	}
	@media (max-width: 580px) {
		.header__logo {
			margin-top: 16px;
			padding-right: 0;
			width: 70%;
			max-width: 220px;
		}
	}
`
export default StyledHeader
