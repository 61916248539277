import React from "react"
import styled from "styled-components"
import { slide as BurgerMenu } from "react-burger-menu"
import { useZIndex } from "../../_globalSettings/var"
import Menu from "./index"
import Image from "../../Image"
import Cross from "../../../images/icons/cross.svg"
import Hamburger from "../../../images/icons/hamburger.svg"

export const StyledMobileMenu = styled.div`
	position: absolute;
	z-index: ${useZIndex("header")}; /* Position and sizing of burger button */
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	.bm-burger-button,
	.bm-cross-button {
		position: fixed;
		right: 22px !important;
		cursor: pointer;
	}

	.bm-cross-button {
		width: 20px !important;
		height: 20px !important;
		top: 28px !important;
	}

	.bm-burger-button {
		width: 28px !important;
		height: 16px !important;
		top: 31px !important;
	}

	.bm-menu-wrap {
		position: fixed;
		height: 100%;
		width: 50% !important;
		@media (max-width: 580px) {
			width: 100% !important;
			min-width: none !important;
		}
	}

	.bm-menu {
		background: #ffff;
	}

	.bm-overlay {
		background: rgba(0, 0, 0, 0.3);
	}
`

const MobileMenu = () => (
	<StyledMobileMenu>
		<BurgerMenu
			right
			slide
			pageWrapId="page-wrap"
			outerContainerId="outer-container"
			customBurgerIcon={<Image data={Hamburger} alt="Menu" />}
			customCrossIcon={<Image data={Cross} alt="Zavřít" />}
		>
			<Menu />
		</BurgerMenu>
	</StyledMobileMenu>
)

export default MobileMenu
