import React from "react"
import { string, shape } from "prop-types"
import StyledHeader from "./style"
import Menu from "../Menu"
import Image from "../../Image"
import { breakpoint, useZIndex } from "../../_globalSettings/var"
import useGlobal from "../../../store"
import A from "../../Link"
import MobileMenu from "../Menu/MobileMenu"
import Row from "../Content/Row"

const Header = ({ logo, logoMobile }) => {
	const [globalState, _set] = useGlobal()
	return (
		<Row
			color="white"
			style={{
				position: "fixed",
				zIndex: `${useZIndex("header")}`,
			}}
		>
			<StyledHeader>
				<A className="header__logo" href="/">
					<Image data={logo.publicURL} alt="ASWA" />
				</A>
				<A className="header__mobile-logo" href="/">
					<Image data={logoMobile.publicURL} alt="ASWA" />
				</A>
				{globalState.windowDimensions.width > breakpoint.default.to ? (
					<Menu />
				) : (
					<MobileMenu />
				)}
			</StyledHeader>
		</Row>
	)
}

export const headerProps = {
	logo: shape({
		publicURL: string.isRequired,
	}).isRequired,
	logoMobile: shape({
		publicURL: string.isRequired,
	}).isRequired,
}

Header.propTypes = { ...headerProps }

export default Header
