import { object, shape, string } from "prop-types"
import React from "react"

import skin from "../_globalSettings/skin"
import OnlyForm from "../Form"
// import useGlobal from "../../store"
import Row from "../Layout/Content/Row"
import StyledNewsletter from "./style"

const Newsletter = ({ headline, paragraph, form }) => {
	// const [globalState, _set] = useGlobal()
	return (
		<Row color="#FFFFFF">
			<StyledNewsletter>
				<h2 className="h3">{headline}</h2>
				<p className="nl__paragraph">{paragraph.intro}</p>
				<OnlyForm
					data={form}
					id="newsletter"
					submitProps={{
						customSkin: skin.blue,
						style: { width: "64px", height: "64px", padding: "0" },
					}}
					beforeSubmit={values => ({
						...values,
						name: values.email,
					})}
				/>
			</StyledNewsletter>
		</Row>
	)
}

export const newsletterProps = {
	image: shape({
		publicURL: string.isRequired,
	}).isRequired,
	headline: string.isRequired,
	paragraph: shape({
		intro: string.isRequired,
		thanks: string.isRequired,
		error: string.isRequired,
	}).isRequired,
	form: object.isRequired, // eslint-disable-line
}

Newsletter.propTypes = { ...newsletterProps }

export default Newsletter
