import React from "react"
import { string, shape, arrayOf } from "prop-types"
import StyledFooter from "./style"
import A from "../../Link"
import HTMLContent from "../../HTMLContent"
import Row from "../Content/Row"
import Image from "../../Image"
import Newsletter from "../../Newsletter"

const Footer = ({
	contact,
	social,
	cookies,
	gdpr,
	logo,
	footerLeft,
	footerMiddle,
	footerRight,
	newsletter,
}) => {
	return (
		<>
			<Newsletter {...newsletter} />
			<StyledFooter>
				<Row wrap="wrap">
					<div className="footer__wrap">
						<div className="footer__content footer__logo">
							<Image data={logo.publicURL} alt="ASWA" />
						</div>
						<HTMLContent
							innerHTML={footerLeft.html}
							className="footer__content footer__links"
						/>
						<HTMLContent
							innerHTML={footerMiddle.html}
							className="footer__content footer__links"
						/>
						<div className="footer__content">
							<h3>{contact.headline}</h3>
							{contact.links.map(item => (
								<div key={item.url} className="footer__contact">
									<A href={item.url}>
										<Image
											data={item.icon.publicURL}
											alt={item.label}
											className="footer__icon"
										/>
										{item.label}
									</A>
								</div>
							))}
							<HTMLContent
								innerHTML={footerRight.html}
								className="footer__address"
							/>
						</div>
						<div className="footer__content">
							<h3>{social.headline}</h3>
							{social.links.map(item => (
								<A href={item.url} key={item.url}>
									<Image
										data={item.icon.publicURL}
										alt={item.label}
										className="footer__icon footer__social"
									/>
								</A>
							))}
						</div>
					</div>
				</Row>
				<Row>
					<div className="footer__bottom-links">
						<A className="footer__down" href={cookies.url}>
							{cookies.label}
						</A>
						<span className="footer__links-divider">•</span>
						<A className="footer__down" href={gdpr.url}>
							{gdpr.label}
						</A>
					</div>
				</Row>
			</StyledFooter>
		</>
	)
}

export const footerProps = {
	contact: shape({
		headline: string.isRequired,
		links: arrayOf(
			shape({
				url: string.isRequired,
				label: string.isRequired,
				icon: shape({
					publicURL: string.isRequired,
				}),
			}).isRequired
		).isRequired,
	}),
	social: shape({
		headline: string.isRequired,
		links: arrayOf(
			shape({
				url: string.isRequired,
				label: string.isRequired,
				icon: shape({
					publicURL: string.isRequired,
				}),
			}).isRequired
		).isRequired,
	}).isRequired,
	cookies: shape({
		url: string.isRequired,
		label: string.isRequired,
	}).isRequired,
	gdpr: shape({
		url: string.isRequired,
		label: string.isRequired,
	}).isRequired,
}

Footer.propTypes = {
	...footerProps,
	footerRight: shape({
		html: string.isRequired,
	}).isRequired,
	footerLeft: shape({
		html: string.isRequired,
	}).isRequired,
	footerMiddle: shape({
		html: string.isRequired,
	}).isRequired,
}

export default Footer
