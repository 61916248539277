import React from "react"
import { string } from "prop-types"
import styled from "styled-components"
import sanitized from "../utils/sanitizeHTML"

const StyledHTMLContent = styled.div`
	h1 {
		margin: 50px 0;
	}
	p {
		margin-bottom: 20px;
	}
	ul,
	ol {
		margin: 0 0 1.5em 1.5em;
	}
`

const HTMLContent = ({ innerHTML, ...rest }) => (
	<StyledHTMLContent
		{...rest}
		dangerouslySetInnerHTML={{ __html: sanitized(innerHTML) }}
	/>
)

export const HTMLContentProps = {
	innerHTML: string,
}

HTMLContent.propTypes = {
	...HTMLContentProps,
}

HTMLContent.defaultProps = {
	innerHTML: "",
}

export default HTMLContent
